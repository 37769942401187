<div class="">
    <div class="breadcrumb">
      <h1>{{titulo}}</h1>
      <ul>
        <li><a href="#">Catalogos</a></li>
        <li>{{titulo}}</li>
      </ul>
    </div>
  
    <div class="separator-breadcrumb border-top"></div>

    <div class="card mb-4">
      <div class="card-body">
        <div class="row">
          <div class="col-lg-12 right text-right align-right">
            <a routerLink="/publicacion/nuevo">
              <button class="btn btn-primary"><i class="i-Add"></i> Nueva publicacion</button>
            </a>
          </div>

          <div class="col-lg-12">
            <ngx-datatable
            style="box-shadow: none"
            class="material fullscreen"
            [columnMode]="'force'"
            [headerHeight]="50"
            [footerHeight]="50"
            [rowHeight]="60"
            [scrollbarV]="false"
            [limit]="10"
            [messages]="{emptyMessage:'No hay información capturada.'}"
            [rows]="publicaciones">
              <!--ngx-datatable-column name="tipo_categoria" [width]="300">
                <ng-template ngx-datatable-header-template>
                  Tipo de categoria
                </ng-template>
              </ngx-datatable-column-->
              <ngx-datatable-column name="nombrePagina">
                <ng-template ngx-datatable-header-template>
                  Nombre de pagina
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column name="toast">
                <ng-template ngx-datatable-header-template>
                  Toast
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column name="publicado">
                <ng-template ngx-datatable-header-template>
                 Estado
                </ng-template>
                <ng-template ngx-datatable-cell-template let-value="value" let-row="row" >
                  <span *ngIf="value==''" style="background: lightgray;padding: 10px;border-radius: 10px;color: #fff;">Borrador</span>
                  <span *ngIf="value!=''" style="background: green;padding: 10px;border-radius: 10px;color: #fff;">Publicado</span>
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column name="locale">
                <ng-template ngx-datatable-header-template>
                 Idioma
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column name="id">
                <ng-template ngx-datatable-header-template>
                  Acciones
                </ng-template>
                <ng-template ngx-datatable-cell-template let-value="value" let-row="row" >
                  <!--a routerLink="/catalogo/sitios/editar/{{value}}" style="margin-right: 20px;">
                    <i class="i-Pen-5"></i>
                  </a>
                  <a routerLink="/catalogo/sitios" (click)="confirm(modalConfirm, value)">
                    <i class="i-Close-Window"></i>
                  </a-->
                </ng-template>
              </ngx-datatable-column>
            </ngx-datatable>
          </div>
        </div>      
      </div>
    </div>

</div>

<ng-template #modalConfirm let-modal>
    <div class="modal-header">
      <h4 class="modal-title" id="modal-title">Eliminar Sitio</h4>
      <button type="button" class="close" aria-label="Close button" aria-describedby="modal-title" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <p><strong>¿Estas seguro de querer eliminar el Sitio?</strong></p>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-outline-secondary btn-rounded" (click)="modal.dismiss('cancel')">Cancelar</button>
      <button type="button" ngbAutofocus class="btn btn-danger btn-rounded" (click)="modal.close('Ok')">Eliminar</button>
    </div>
</ng-template>