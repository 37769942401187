import { Component, OnInit } from '@angular/core';
import { ProductService } from 'src/app/shared/services/product.service';
import { ResourceService } from '../../resource.service';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-usuarios',
  templateUrl: './usuarios.component.html',
  providers: [ResourceService]
})
export class UsuariosComponent implements OnInit {

  usuarios=[];

  constructor(
    private productService: ProductService,
    private $request: ResourceService,
    private $router: Router,
    private modalService: NgbModal,
    private toastr: ToastrService
  ) { }

  ngOnInit() {
    console.log("Cotizacion");
    //this.products$ = this.productService.getProducts();
    this.buscarClientes()
  }

  buscarClientes(){
    this.$request.get(
    '/user/',
    (response: any) => {
      console.log('usuarios', response);
      this.usuarios = response;
      this.usuarios.forEach(eachObj => {
        console.log(eachObj.first_name);
        eachObj.nombre=eachObj.first_name;
        eachObj.apellido=eachObj.last_name;
      });
    },
    (response: any) => {
      console.log('error', response);
      if(response.statusText=="Unauthorized"){
        console.log("redireccion")
        this.$router.navigateByUrl('/sessions/signin');
      }
    });
  }

  confirmResut;

  confirm(content, value) {
    this.$request.get(
    '/ClientLocalizar/?cliente='+value,
    (response: any) => {
      console.log('pagos', response);
      if (response=="true") {

        this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', centered: true })
        .result.then((result) => {
          this.confirmResut = `Closed with: ${result}`;

          this.$request.get(
          '/Cotizacion/?cliente='+value,
          (response: any) => {
            console.log('cotizacion', response);
            if(response.length<1){
              this.eliminarLista(value)
            }else{
              this.toastr.error('No se puede eliminar un cliente con una cotización.', '¡Error!', {progressBar: true});
            }
          },
          (response: any) => {
            console.log('error', response);
            if(response.statusText=="Unauthorized"){
              console.log("redireccion")
              this.$router.navigateByUrl('/sessions/signin');
            }
          });
        }, (reason) => {
          this.confirmResut = `Dismissed with: ${reason}`;
        });

      }else{
        this.toastr.error('No se puede eliminar un cliente con una cotización.', '¡Error!', {progressBar: true});
      }
    },
    (response: any) => {
      console.log('error', response);
      if(response.statusText=="Unauthorized"){
        console.log("redireccion")
        this.$router.navigateByUrl('/sessions/signin');
      }
    });

  }

  eliminarLista(id){
    this.$request.delete(
    '/users/'+id+'/',
    (response: any) => {
      console.log('pagos', response);
      this.buscarClientes();
    },
    (response: any) => {
      console.log('error', response);
      if(response.statusText=="Unauthorized"){
        console.log("redireccion")
        this.$router.navigateByUrl('/sessions/signin');
      }
    });
  }
}
