<div class="">
  <div class="breadcrumb">
    <h1>{{titulo}}</h1>
    <ul>
      <li><a routerLink="/catalogo/sitios">Publicaciones</a></li>
      <li>{{titulo}}</li>
    </ul>
  </div>
  
  <div class="separator-breadcrumb border-top"></div>
  <form method="post" class="form" (ngSubmit)="submit(pagosForm.valid)" #pagosForm="ngForm" novalidate>
    <ngb-accordion #acc="ngbAccordion" [closeOthers]="true" activeIds="ngb-panel-0" *ngFor="let item of resorts">
      <ngb-panel title="{{item.nombre}}">
        <ng-template ngbPanelContent>
          <div [@animate]="{value:'*',params:{y:'20px',opacity:'0',delay:'0ms', duration: '400ms'}}">
            <div class="row">
              <div class="col-lg-4">
                <label>Sitio</label>
                <input type="text" class="form-control" id="nombre" placeholder="Sitio" [(ngModel)]="data.nombre" name="nombre" [className]="validation.nombre.invalid ? 'form-control error-class' : 'form-control'" (click)="limpiar('nombre')" (change)="limpiar('nombre')">
                <div class="errores" *ngIf="validation.nombre.invalid">
                  <div *ngIf="validation.nombre.errors.required">
                    Sitio es requerido
                  </div>
                  <div *ngIf="validation.nombre.errors.format">
                    Sitio no tiene un formato valido
                  </div>
                </div>
              </div>
              <div class="col-lg-4">
                <label>Pestaña de lectura</label>
                <input type="text" class="form-control" id="pestanaLectura" placeholder="Pestaña de lectura" [(ngModel)]="data.pestanaLectura" name="pestanaLectura">
              </div>
              <div class="col-lg-4">
                <label>Marca</label>
                <input type="text" class="form-control" id="marca" placeholder="Marca" [(ngModel)]="data.marca" name="marca">
              </div>
            </div>
          </div>
        </ng-template>
      </ngb-panel>
    </ngb-accordion>
  </form>
</div>