import { Component, OnInit } from '@angular/core';
import { ProductService } from 'src/app/shared/services/product.service';
import { ResourceService } from '../../resource.service';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-catalogo',
  templateUrl: './catalogo.component.html',
  providers: [ResourceService]
})
export class CatalogoComponent implements OnInit {

  products$;
  catalogos=[];

  constructor(
    public productService: ProductService,
    public $request: ResourceService,
    public $router: Router,
    public modalService: NgbModal
  ) { }

  ngOnInit() {
    console.log("Cotizacion");
    //this.products$ = this.productService.getProducts();

    this.catalogos = [{
          id: 1,
          nombre: "Producto",
          frontStaging: "Venta",
          apiStaging: "Torre 1 Piso 1",
          front: "T1-P1 001",
          api: "T1-P1 001"
        }]
    console.log(this.products$);
    this.buscarCatalogos()
  }

  confirmResut;

  buscarCatalogos(){
    this.$request.get(
    '/Catalogo/',
    (response: any) => {
      console.log('pagos', response);
      this.catalogos = response;
      this.catalogos.forEach(eachObj => {
        eachObj.apiStaging=eachObj.apiStaging.replace("content-manager/collection-types/", "admin/");
        eachObj.api=eachObj.api.replace("content-manager/collection-types/", "admin/");
      });
    },
    (response: any) => {
      console.log('error', response);
      if(response.statusText=="Unauthorized"){
        console.log("redireccion")
        this.$router.navigateByUrl('/sessions/signin');
      }
    });
  }

  confirm(content, value) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', centered: true })
    .result.then((result) => {
      this.confirmResut = `Closed with: ${result}`;
      this.eliminarCatalogos(value)
    }, (reason) => {
      this.confirmResut = `Dismissed with: ${reason}`;
    });
  }

  eliminarCatalogos(id){
    this.$request.delete(
    '/Catalogo/'+id+'/',
    (response: any) => {
      console.log('pagos', response);
      this.buscarCatalogos();
    },
    (response: any) => {
      console.log('error', response);
      if(response.statusText=="Unauthorized"){
        console.log("redireccion")
        this.$router.navigateByUrl('/sessions/signin');
      }
    });
  }

  titulo="Sitios";
  tipoProyecto="catalogo";
}