import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SharedModule } from './shared/shared.module';
import { InMemoryWebApiModule } from 'angular-in-memory-web-api';
import { InMemoryDataService } from './shared/inmemory-db/inmemory-db.service';
import { HttpClientModule } from '@angular/common/http';

import { CommonModule } from '@angular/common';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { NgxPaginationModule } from 'ngx-pagination';
import { NgxMaskModule, IConfig } from 'ngx-mask'
export const options: Partial<IConfig> | (() => Partial<IConfig>) = null;


import { ImageCropperModule } from 'ngx-img-cropper';
import { SharedComponentsModule } from 'src/app/shared/components/shared-components.module';
import { FormWizardModule } from 'src/app/shared/components/form-wizard/form-wizard.module';
import { TextMaskModule } from 'angular2-text-mask';
import { CustomFormsModule } from 'ngx-custom-validators';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TagInputModule } from 'ngx-chips';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { CurrencyMaskModule } from "ng2-currency-mask";
import {AutocompleteLibModule} from 'angular-ng-autocomplete';
import { NgSelectModule } from '@ng-select/ng-select';

import {CatalogoComponent} from './views/catalogo/catalogo.component';
import {CatalogoNuevoComponent, CatalogoEditarComponent} from './views/catalogo/catalogoNuevo/catalogo-nuevo.component';
import {UsuariosComponent} from './views/usuarios/usuarios.component';
import {UsuarioNuevoComponent, UsuarioEditarComponent} from './views/usuarios/usuarioNuevo/usuario-nuevo.component';
import {PublicacionComponent} from './views/publicacion/publicacion.component';
import {PublicacionNuevoComponent, PublicacionEditarComponent} from './views/publicacion/publicacionNuevo/publicacion-nuevo.component';

@NgModule({
  declarations: [
    AppComponent,

    CatalogoComponent,
    CatalogoNuevoComponent,
    CatalogoEditarComponent,
    UsuariosComponent,
    UsuarioNuevoComponent,
    UsuarioEditarComponent,
    PublicacionComponent,
    PublicacionNuevoComponent,
    PublicacionEditarComponent
  ],
  imports: [
    BrowserModule,
    SharedModule,
    HttpClientModule,
    BrowserAnimationsModule,
    InMemoryWebApiModule.forRoot(InMemoryDataService, { passThruUnknownUrl: true }),
    AppRoutingModule,

    CommonModule,
    NgxDatatableModule,
    NgxPaginationModule,

    ImageCropperModule,
    SharedComponentsModule,
    FormWizardModule,
    TextMaskModule,
    CustomFormsModule,
    ReactiveFormsModule,
    FormsModule,
    NgbModule,
    TagInputModule,
    AngularEditorModule,
    FontAwesomeModule,
    CurrencyMaskModule,
    NgxMaskModule.forRoot(),
    AutocompleteLibModule,
    NgSelectModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
