import { NgModule } from '@angular/core';
import { Routes, Router, RouterModule, NavigationStart, NavigationEnd, ActivatedRoute} from '@angular/router';
import { filter } from 'rxjs/operators';
import {Location} from '@angular/common';
import { CookieService } from 'ngx-cookie-service';
import settings from './settings';
//import { MenuItems } from './core/menu/menu-items/menu-items';

import { AuthLayoutComponent } from './shared/components/layouts/auth-layout/auth-layout.component';
import { AuthGaurd } from './shared/services/auth.gaurd';
import { BlankLayoutComponent } from './shared/components/layouts/blank-layout/blank-layout.component';
import { AdminLayoutSidebarLargeComponent } from './shared/components/layouts/admin-layout-sidebar-large/admin-layout-sidebar-large.component';

import {CatalogoComponent} from './views/catalogo/catalogo.component';
import {CatalogoNuevoComponent, CatalogoEditarComponent} from './views/catalogo/catalogoNuevo/catalogo-nuevo.component';
import {UsuariosComponent} from './views/usuarios/usuarios.component';
import {UsuarioNuevoComponent, UsuarioEditarComponent} from './views/usuarios/usuarioNuevo/usuario-nuevo.component';
import {PublicacionComponent} from './views/publicacion/publicacion.component';
import {PublicacionNuevoComponent, PublicacionEditarComponent} from './views/publicacion/publicacionNuevo/publicacion-nuevo.component';

const adminRoutes: Routes = [
    {
      path: 'dashboard',
      loadChildren: () => import('./views/dashboard/dashboard.module').then(m => m.DashboardModule),
      data: {
        permission: 'add_user',
      }
    },
    {
      path: 'uikits',
      loadChildren: () => import('./views/ui-kits/ui-kits.module').then(m => m.UiKitsModule)
    },
    {
      path: 'forms',
      loadChildren: () => import('./views/forms/forms.module').then(m => m.AppFormsModule)
    },
    {
      path: 'invoice',
      loadChildren: () => import('./views/invoice/invoice.module').then(m => m.InvoiceModule)
    },
    {
      path: 'inbox',
      loadChildren: () => import('./views/inbox/inbox.module').then(m => m.InboxModule)
    },
    {
      path: 'calendar',
      loadChildren: () => import('./views/calendar/calendar.module').then(m => m.CalendarAppModule)
    },
    {
      path: 'chat',
      loadChildren: () => import('./views/chat/chat.module').then(m => m.ChatModule)
    },
    {
      path: 'contacts',
      loadChildren: () => import('./views/contacts/contacts.module').then(m => m.ContactsModule)
    },
    {
      path: 'tables',
      loadChildren: () => import('./views/data-tables/data-tables.module').then(m => m.DataTablesModule),
      data: {
        permission: 'cotizacion.change_cotizacion',
      }
    },
    {
      path: 'pages',
      loadChildren: () => import('./views/pages/pages.module').then(m => m.PagesModule)
    },
    {
        path: 'icons',
        loadChildren: () => import('./views/icons/icons.module').then(m => m.IconsModule)
    },

    {
      path: 'catalogo/sitios',
      component: CatalogoComponent,
      data: {
        permission: 'add_catalogo',
      }
    },
    {
      path: 'catalogo/sitios/nuevo',
      component: CatalogoNuevoComponent,
      data: {
        permission: 'add_catalogo',
      }
    },
    {
      path: 'catalogo/sitios/editar/:id',
      component: CatalogoEditarComponent,
      data: {
        permission: 'change_catalogo',
      }
    },

    {
      path: 'publicacion',
      component: PublicacionComponent,
      data: {
        permission: 'add_catalogo',
      }
    },
    {
      path: 'publicacion/nuevo',
      component: PublicacionNuevoComponent,
      data: {
        permission: 'add_catalogo',
      }
    },
    {
      path: 'publicacion/editar/:id',
      component: PublicacionEditarComponent,
      data: {
        permission: 'change_catalogo',
      }
    },

    {
      path: 'usuarios',
      component: UsuariosComponent,
      data: {
        permission: 'add_user',
      }
    },
    {
      path: 'usuario/nuevo',
      component: UsuarioNuevoComponent,
      data: {
        permission: 'add_user',
      }
    },
    {
      path: 'usuario/editar/:id',
      component: UsuarioEditarComponent,
      data: {
        permission: 'change_user',
      }
    },
    
  ];

const routes: Routes = [
  {
    path: '',
    redirectTo: 'dashboard/v1',
    pathMatch: 'full'
  },
  {
    path: '',
    component: AuthLayoutComponent,
    children: [
      {
        path: 'sessions',
        loadChildren: () => import('./views/sessions/sessions.module').then(m => m.SessionsModule)
      }
    ]
  },
  {
    path: '',
    component: BlankLayoutComponent,
    children: [
      {
        path: 'others',
        loadChildren: () => import('./views/others/others.module').then(m => m.OthersModule)
      }
    ]
  },
  {
    path: '',
    component: AdminLayoutSidebarLargeComponent,
    canActivate: [AuthGaurd],
    children: adminRoutes
  },
  {
    path: '**',
    redirectTo: 'others/404'
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: false, relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule {
  constructor(
    //public menuItems: MenuItems,
    public $router: Router,
    public $active: ActivatedRoute,
    public $location: Location,
    private $cookies: CookieService) {

    $router.events.pipe(
        filter(event => event instanceof NavigationStart)
    ).subscribe(() => {
      //console.log(this.$router.routerState);
      //const test: any = this.$router.routerState.root.firstChild.firstChild.snapshot;
    });

    $router.events.pipe(
        filter(event => event instanceof NavigationEnd)
    ).subscribe(() => {
      const test: any = this.$router.routerState.root.firstChild.firstChild.snapshot;
      const URIPermission = test.data.permission;
      console.log("permissions_key:",settings.permissions_key);
      const UsrPermissions = localStorage.getItem(settings.permissions_key);
      //console.log("Permiso: ",URIPermission);
      //console.log("Permisos: ",UsrPermissions);
      //console.log(UsrPermissions.includes(URIPermission));

      if (URIPermission !== 'any'  && !UsrPermissions.includes(URIPermission)) {
        // this.$location.back();
        this.$router.navigateByUrl('sessions/signin')
      }

    });

  }
}
