import { Component, OnInit } from '@angular/core';
import { ProductService } from 'src/app/shared/services/product.service';
import { ResourceService } from '../../../resource.service';
import { Router } from '@angular/router';
import {  ActivatedRoute, } from '@angular/router';
import { CustomValidators } from 'ngx-custom-validators';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Location } from '@angular/common'
import { DomSanitizer} from '@angular/platform-browser';

@Component({
  selector: 'app-publicacion-nuevo',
  templateUrl: './publicacion-nuevo.component.html',
  styleUrls: ['./publicacion-nuevo.component.scss'],
  providers: [ResourceService]
})
export class PublicacionNuevoComponent implements OnInit {
  formBasic: FormGroup;
  products$;
  products=[];
  loading= false;
  private sub: any;
  proyecto: number;

  titulo="Nuevo Sitio";

  data={
    id: null,
    nombre: null,
    pestanaLectura: null,
    marca: null, 
    frontStaging: null,
    apiStaging: null,
    front: null, 
    api: null, 
    user: null,
    password: null,
    token: null, 
    booking: null, 
    reservhotel: null, 
    reservhotelMx: null, 
    hotelImg: null,
    imgTransparente: null, 
    pestana: null, 
    transporteID: null,
    transporteEsID: null,
    transporteImgID: null, 
    transporteImgEsID: null, 
    kidID: null, 
    kidEsID: null, 
    kidImgID: null,
    kidImgEsID: null,
    aniversarioID: null, 
    aniversarioEsID: null, 
    aniversarioImgID: null,
    aniversarioImgEsID: null,
    lunaID: null,
    lunaEsID: null,
    lunaImgID: null, 
    lunaImgEsID: null, 
    transportacionEN: null,
    ninosAdolescentesEN: null, 
    aniversarioEN: null, 
    lunaMielEN: null,
    aniversarioES: null, 
    lunaMielES: null,
    transportacionES: null,
    ninosAdolescentesES: null, 
  }

  documentoName=null;
  resorts=[{id:1},{id:2}];

  constructor(
    private productService: ProductService,
    private $request: ResourceService,
    private $router: Router,
    private fb: FormBuilder,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    private sanitizer: DomSanitizer,
  ) {
    this.sub = this.route.params.subscribe(params => {
        this.proyecto = +params['id']; // (+) converts string 'id' to a number
      });
  }

  ngOnInit() {
    this.buildFormBasic();
    console.log("Cotizacion");
    //this.products$ = this.productService.getProducts();
    console.log(this.data);
    this.getCatalogos();
  }

  onMouseUp(evento, item, nombre) {}

  buildFormBasic() {
    this.formBasic = this.fb.group({
      experience: []
    });
  }

  getCatalogos(){
    this.$request.get(
    '/Catalogo/',
    (response: any) => {
      console.log('pagos', response);
      this.resorts = response;
    },
    (response: any) => {
      console.log('error', response);
      if(response.statusText=="Unauthorized"){
        console.log("redireccion")
        this.$router.navigateByUrl('/sessions/signin');
      }
    });
  }

  submit(valid) {
    this.loading = true;
    //if(valid){
    if(this.validForm()){

        this.$request.new(
        '/Catalogo/',
        this.data,
        (response: any) => {
          //setTimeout(() => {
            this.loading = false;
            this.$router.navigateByUrl('/catalogo/sitios');
            this.toastr.success('Se guardo el catalogo.', '¡Exito!', {progressBar: true});
          //}, 3000);
        },
        (response: any) => {
          console.log('error', response);
          if(response.statusText=="Unauthorized"){
            console.log("redireccion")
            this.$router.navigateByUrl('/sessions/signin');
          }
        });
      
    }else{
      console.log("Corregir");
    }
  }

  validation={
    nombre: {invalid: false, errors:{required: false}},
    front: {invalid: false, errors:{required: false}},
    api: {invalid: false, errors:{required: false}},
  }

  validForm(){
    var valid=true;
    if(!this.data.nombre){
      valid=false;
      this.validation.nombre.invalid=true;
      this.validation.nombre.errors.required=true;
    }
    if(!this.data.front){
      valid=false;
      this.validation.front.invalid=true;
      this.validation.front.errors.required=true;
    }
    if(!this.data.api){
      valid=false;
      this.validation.api.invalid=true;
      this.validation.api.errors.required=true;
    }else{
    }

    this.loading = false;

    return valid
  }
  limpiar(id){
    if(id=="nombre"){
      this.validation.nombre.invalid=false;
      this.validation.nombre.errors.required=false;
    }
    if(id=="front"){
      this.validation.front.invalid=false;
      this.validation.front.errors.required=false;
    }
    if(id=="api"){
      this.validation.api.invalid=false;
      this.validation.api.errors.required=false;
    }
  }
   document1=null;
   document2=null;

}

@Component({
  selector: 'app-publicacion-nuevo',
  templateUrl: './publicacion-nuevo.component.html',
  styleUrls: ['./publicacion-nuevo.component.scss'],
  providers: [ResourceService]
})
export class PublicacionEditarComponent implements OnInit {
  formBasic: FormGroup;
  products$;
  products=[];
  loading= false;
  private sub: any;
  id: number;
  resorts=[{id:1},{id:2}];

  titulo="Nuevo Sitio";

  data={
    id: null,
    nombre: null,
    pestanaLectura: null,
    marca: null, 
    frontStaging: null,
    apiStaging: null,
    front: null, 
    api: null, 
    user: null,
    password: null,
    token: null, 
    booking: null, 
    reservhotel: null, 
    reservhotelMx: null, 
    hotelImg: null,
    imgTransparente: null, 
    pestana: null, 
    transporteID: null,
    transporteEsID: null,
    transporteImgID: null, 
    transporteImgEsID: null, 
    kidID: null, 
    kidEsID: null, 
    kidImgID: null,
    kidImgEsID: null,
    aniversarioID: null, 
    aniversarioEsID: null, 
    aniversarioImgID: null,
    aniversarioImgEsID: null,
    lunaID: null,
    lunaEsID: null,
    lunaImgID: null, 
    lunaImgEsID: null, 
    transportacionEN: null,
    ninosAdolescentesEN: null, 
    aniversarioEN: null, 
    lunaMielEN: null,
    aniversarioES: null, 
    lunaMielES: null,
    transportacionES: null,
    ninosAdolescentesES: null, 
  }

  documentoName=null;

  constructor(
    private productService: ProductService,
    private $request: ResourceService,
    private $router: Router,
    private fb: FormBuilder,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    private sanitizer: DomSanitizer,
  ) {
    this.sub = this.route.params.subscribe(params => {
        this.id = +params['id']; // (+) converts string 'id' to a number
      });
  }

  ngOnInit() {
    this.buildFormBasic();
    console.log("Cotizacion");
    //this.products$ = this.productService.getProducts();
    console.log(this.data);
    this.getCatalogos();
  }

  onMouseUp(evento, item, nombre) {}

  buildFormBasic() {
    this.formBasic = this.fb.group({
      experience: []
    });
  }

  getCatalogos(){
    this.$request.get(
    '/Catalogo/'+this.id+'/',
    (response: any) => {
      console.log('pagos', response);
      this.data = response;
    },
    (response: any) => {
      console.log('error', response);
      if(response.statusText=="Unauthorized"){
        console.log("redireccion")
        this.$router.navigateByUrl('/sessions/signin');
      }
    });
  }

  submit(valid) {
    this.loading = true;
    //if(valid){
    if(this.validForm()){

        this.$request.update(
        '/Catalogo/'+this.id+'/',
        this.data,
        (response: any) => {
          //setTimeout(() => {
            this.loading = false;
            this.$router.navigateByUrl('/catalogo/sitios');
            this.toastr.success('Se guardo el catalogo.', '¡Exito!', {progressBar: true});
          //}, 3000);
        },
        (response: any) => {
          console.log('error', response);
          if(response.statusText=="Unauthorized"){
            console.log("redireccion")
            this.$router.navigateByUrl('/sessions/signin');
          }
        });
      
    }else{
      console.log("Corregir");
    }
  }

  validation={
    nombre: {invalid: false, errors:{required: false}},
    front: {invalid: false, errors:{required: false}},
    api: {invalid: false, errors:{required: false}},
  }

  validForm(){
    var valid=true;
    if(!this.data.nombre){
      valid=false;
      this.validation.nombre.invalid=true;
      this.validation.nombre.errors.required=true;
    }
    if(!this.data.front){
      valid=false;
      this.validation.front.invalid=true;
      this.validation.front.errors.required=true;
    }
    if(!this.data.api){
      valid=false;
      this.validation.api.invalid=true;
      this.validation.api.errors.required=true;
    }else{
    }

    this.loading = false;

    return valid
  }
  limpiar(id){
    if(id=="nombre"){
      this.validation.nombre.invalid=false;
      this.validation.nombre.errors.required=false;
    }
    if(id=="front"){
      this.validation.front.invalid=false;
      this.validation.front.errors.required=false;
    }
    if(id=="api"){
      this.validation.api.invalid=false;
      this.validation.api.errors.required=false;
    }
  }
   document1=null;
   document2=null;
}
