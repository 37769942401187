<div class="">
    <div class="breadcrumb">
      <h1>{{titulo}}</h1>
      <ul>
        <li><a href="#">Catalogos</a></li>
        <li>{{titulo}}</li>
      </ul>
    </div>
  
    <div class="separator-breadcrumb border-top"></div>

    <div class="card mb-4">
      <div class="card-body">
        <div class="row">
          <div class="col-lg-12 right text-right align-right">
            <a routerLink="/catalogo/sitios/nuevo">
              <button class="btn btn-primary"><i class="i-Add"></i> Nuevo Sitio</button>
            </a>
          </div>

          <div class="col-lg-12">
            <ngx-datatable
            style="box-shadow: none"
            class="material fullscreen"
            [columnMode]="'force'"
            [headerHeight]="50"
            [footerHeight]="50"
            [rowHeight]="60"
            [scrollbarV]="false"
            [limit]="10"
            [messages]="{emptyMessage:'No hay información capturada.'}"
            [rows]="catalogos">
              <!--ngx-datatable-column name="tipo_categoria" [width]="300">
                <ng-template ngx-datatable-header-template>
                  Tipo de categoria
                </ng-template>
              </ngx-datatable-column-->
              <ngx-datatable-column name="nombre">
                <ng-template ngx-datatable-header-template>
                  Sitio
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column name="frontStaging">
                <ng-template ngx-datatable-header-template>
                  Front Staging
                </ng-template>
                <ng-template ngx-datatable-cell-template let-value="value" let-row="row" >
                  <a href="{{value}}" target="_blanck">{{value}}</a>
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column name="apiStaging">
                <ng-template ngx-datatable-header-template>
                  API Staging
                </ng-template>
                <ng-template ngx-datatable-cell-template let-value="value" let-row="row" >
                  <a href="{{value}}" target="_blanck">{{value}}</a>
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column name="front">
                <ng-template ngx-datatable-header-template>
                  Front
                </ng-template>
                <ng-template ngx-datatable-cell-template let-value="value" let-row="row" >
                  <a href="{{value}}" target="_blanck">{{value}}</a>
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column name="api">
                <ng-template ngx-datatable-header-template>
                  API
                </ng-template>
                <ng-template ngx-datatable-cell-template let-value="value" let-row="row" >
                  <a href="{{value}}" target="_blanck">{{value}}</a>
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column name="id">
                <ng-template ngx-datatable-header-template>
                  Acciones
                </ng-template>
                <ng-template ngx-datatable-cell-template let-value="value" let-row="row" >
                  <a routerLink="/catalogo/sitios/editar/{{value}}" style="margin-right: 20px;">
                    <i class="i-Pen-5"></i>
                  </a>
                  <a routerLink="/catalogo/sitios" (click)="confirm(modalConfirm, value)">
                    <i class="i-Close-Window"></i>
                  </a>
                </ng-template>
              </ngx-datatable-column>
            </ngx-datatable>
          </div>
        </div>      
      </div>
    </div>

</div>

<ng-template #modalConfirm let-modal>
    <div class="modal-header">
      <h4 class="modal-title" id="modal-title">Eliminar Sitio</h4>
      <button type="button" class="close" aria-label="Close button" aria-describedby="modal-title" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <p><strong>¿Estas seguro de querer eliminar el Sitio?</strong></p>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-outline-secondary btn-rounded" (click)="modal.dismiss('cancel')">Cancelar</button>
      <button type="button" ngbAutofocus class="btn btn-danger btn-rounded" (click)="modal.close('Ok')">Eliminar</button>
    </div>
</ng-template>