<div class="">
    <div class="breadcrumb">
      <h1>Nuevo Usuario</h1>
      <ul>
        <li><a routerLink="/usuarios">Usuarios</a></li>
        <li>Nuevo Usuario</li>
      </ul>
    </div>
  
    <div class="separator-breadcrumb border-top"></div>
    <div class="card mb-4">
      <div class="card-body">
        <form method="post" class="form" (ngSubmit)="submit(pagosForm.valid)" #pagosForm="ngForm" novalidate>

        <div class="row">
          <div class="col-lg-4">
            <label>Nombre</label>
            <input type="text" class="form-control" id="nombre" placeholder="Nombre" name="nombre" [(ngModel)]="data.first_name" [className]="validation.nombre.invalid ? 'form-control error-class' : 'form-control'" (click)="limpiar('nombre')" (change)="limpiar('nombre')">

            <div class="errores" *ngIf="validation.nombre.invalid">
              <div *ngIf="validation.nombre.errors.required">
                Nombre es requerido
              </div>
              <div *ngIf="validation.nombre.errors.format">
                Nombre no tiene un formato valido
              </div>
            </div>
          </div>
          <div class="col-lg-4">
            <label>Apellido</label>
            <input type="text" class="form-control" id="apellido" placeholder="Apellido" name="apellido" [(ngModel)]="data.last_name" [className]="validation.apellido.invalid ? 'form-control error-class' : 'form-control'" (click)="limpiar('apellido')" (change)="limpiar('apellido')">

            <div class="errores" *ngIf="validation.apellido.invalid">
              <div *ngIf="validation.apellido.errors.required">
                Apellido es requerido
              </div>
              <div *ngIf="validation.apellido.errors.format">
                Apellido no tiene un formato valido
              </div>
            </div>
          </div>
          <div class="col-lg-4">
            <label>Correo</label>
            <input type="text" class="form-control" id="email" placeholder="email" name="email" [(ngModel)]="data.email" [className]="validation.email.invalid ? 'form-control error-class' : 'form-control'" (click)="limpiar('email')" (change)="limpiar('email')">

            <div class="errores" *ngIf="validation.email.invalid">
              <div *ngIf="validation.email.errors.required">
                Correo es requerido
              </div>
              <div *ngIf="validation.email.errors.format">
                Correo no tiene un formato valido
              </div>
            </div>
          </div>
          <div class="col-lg-4">
            <label>Tipo</label>
            <!--input type="text" class="form-control" id="tipo" placeholder="tipo" name="tipo" [(ngModel)]="data.tipo" [className]="validation.tipo.invalid ? 'form-control error-class' : 'form-control'" (click)="limpiar('tipo')" (change)="limpiar('tipo')"-->

            <select class="form-control" id="tipo" placeholder="tipo" name="tipo" [(ngModel)]="data.tipo" [className]="validation.tipo.invalid ? 'form-control error-class' : 'form-control'" (click)="limpiar('tipo')" (change)="limpiar('tipo')">
              <option value="">Seleccione un tipo</option>
              <option *ngFor="let item of grupos" value="{{item.id}}">{{item.name}}</option>
            </select>

            <div class="errores" *ngIf="validation.tipo.invalid">
              <div *ngIf="validation.tipo.errors.required">
                Tipo es requerido
              </div>
              <div *ngIf="validation.tipo.errors.format">
                Tipo no tiene un formato valido
              </div>
            </div>
          </div>
          <div class="col-lg-4">
            <label>Usuario</label>
            <input type="text" class="form-control" id="username" placeholder="username" name="username" [(ngModel)]="data.username" (click)="limpiar('username')" (change)="limpiar('username')">
          </div>
          <div class="col-lg-4">
            <label>Contraseña</label>
            <input type="password" class="form-control" id="password" placeholder="password" name="password" [(ngModel)]="data.password" (click)="limpiar('password')" (change)="limpiar('password')">
          </div>
          <!--div class="col-lg-4">
            <label>Contraseña</label>
            <input type="password" class="form-control" id="password2" placeholder="password2" name="password2" [(ngModel)]="data.password2" (click)="limpiar('password2')" (change)="limpiar('password2')">
          </div-->
        </div>
        <div class="row text-right" style="margin-top: 10px;">
          <div class="col-lg-12 text-right">
            <a routerLink="/usuarios">
              <btn-loading type="button" btnClass="btn-primary btn-rounded btn-danger" style="margin-right: 20px;"><i class="i-Close-Window"></i> Cancelar</btn-loading>
            </a>
            <btn-loading type="submit" btnClass="btn-primary btn-rounded btn-success" [loading]="loading"><i class="i-Data-Save"></i> Guardar</btn-loading>
          </div>
        </div>

        </form>
      </div>
    </div>
</div>