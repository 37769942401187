import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
import { Router, RouterEvent, NavigationEnd } from '@angular/router';
import Settings from './settings';

@Injectable()
export class ResourceService {

  headers: HttpHeaders;

  constructor(
    private http: HttpClient,
    private cookieService: CookieService,
    private $router: Router
  ) {
    // this.headers.set('Authorization', 'my-auth-token'),
    this.headers = new HttpHeaders().set(
      'Content-Type', 'application/json'
    ).set(
      'Accept', 'application/json'
    ).set(
      'X-CSRFToken', cookieService.get('csrftoken')
    );
  }

  get(uri: string, success: any, error: any): void {
    const _url = '/api' + uri;
    const _token = this.cookieService.get(Settings.token_name);
    this.http.get(
      _url,
      {  headers: this.headers.set('Authorization', 'Token ' + _token)}
    ).subscribe(success, error);
  }

  post(uri: string, data: any, success: any, error: any): void {
    const _url = '/api' + uri;
    const _token = this.cookieService.get(Settings.token_name);
    this.http.post(
      _url,
      data, {  headers: this.headers.set('Authorization', 'Token ' + _token)}
    ).subscribe(success, error);
  }

  new(uri: string, data: any, success: any, error: any): void {
    return this.post(uri, data, success, error);
  }

  update(uri: string, data: any, success: any, error: any): void {
    const _url = '/api' + uri;
    const _token = this.cookieService.get(Settings.token_name);
    this.http.put(
      _url,
      data, {  headers: this.headers.set('Authorization', 'Token ' + _token)}
    ).subscribe(success, error);
  }

  delete(uri: string, success: any, error: any): void {
    const _url = '/api' + uri;
    const _token = this.cookieService.get(Settings.token_name);
    this.http.delete(
      _url,
      {  headers: this.headers.set('Authorization', 'Token ' + _token)}
    ).subscribe(success, error);
  }

  auth(
    username: string,
    password: string,
    success: any, error: any): void {
    const self = this;
    this.post(
      '/api-token-auth/',
      {username: username, password: password},
      (response: HttpResponse<any>) => {
        if ('token' in response) {
          success(response);
        } else {
          error(response);
        }
      }, (response: HttpResponse<any>) => {
        // console.log('Error aPI', response);
        error(response);
      });
  }

  consecutiveMovement(sucursal:any, tabla:any, success: any, error: any): void {
    const _url = '/api/FolioConsecutivo/' + '?sucursal='+sucursal+'&tabla='+tabla;
    const _token = this.cookieService.get(Settings.token_name);
    this.http.get(
      _url,
      {  headers: this.headers.set('Authorization', 'Token ' + _token)}
    ).subscribe(success, error);
  }

  logout(redirectTo: string = '/sessions/signin'): void {
    // console.log( 'Token name', Settings.token_name);
    this.cookieService.delete(Settings.token_name);
    localStorage.removeItem(Settings.permissions_key);
    this.$router.navigateByUrl('/sessions/signin');
  }

}

@Injectable()
export class PreviousRouteService {
  private previousUrl: string = undefined;
  private currentUrl: string = undefined;

  constructor(private router : Router) {
    this.currentUrl = this.router.url;
    router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {        
        this.previousUrl = this.currentUrl;
        this.currentUrl = event.url;
      };
    });
  }

  public getPreviousUrl(){
    return this.previousUrl;
  }
}