import { Component, OnInit } from '@angular/core';
import { ProductService } from 'src/app/shared/services/product.service';
import { ResourceService } from '../../resource.service';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';

@Component({
  selector: 'app-publicacion',
  templateUrl: './publicacion.component.html',
  providers: [ResourceService]
})
export class PublicacionComponent implements OnInit {

  products$;
  publicaciones=[];

  headers =  new HttpHeaders({
      'Content-Type':  'application/json',
      'Access-Control-Allow-Origin': '*',
      Authorization: 'my-auth-token'
    });

  constructor(
    public productService: ProductService,
    public $request: ResourceService,
    public $router: Router,
    public modalService: NgbModal,
    private http: HttpClient,
  ) { }

  ngOnInit() {
    console.log("Cotizacion");
    //this.products$ = this.productService.getProducts();

    this.publicaciones = [{
          id: 1,
          nombre: "Producto",
          frontStaging: "Venta",
          apiStaging: "Torre 1 Piso 1",
          front: "T1-P1 001",
          api: "T1-P1 001"
        }]
    console.log(this.products$);
    this.buscarPublicacion();
    //this.getPub();
  }

  getPub(){
    const _url = 'https://5y1wgf64k6.execute-api.us-east-1.amazonaws.com/admin/plugins/content-manager/collectionType/application::ofertas.ofertas?page=1&pageSize=100&_sort=id:DESC&plugins[i18n][locale]=en';
    const _token = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MywiaWF0IjoxNjYxODczNjYyLCJleHAiOjE2NjQ0NjU2NjJ9.Ve26RdAVHwAXrUZx_52hSPmKZjHVkMU43M5L9XEFyUA";
    this.http.get(
      _url,
      {  headers: this.headers.set('Authorization', 'Bearer ' + _token)}
    ).subscribe();
  }

  confirmResut;

  buscarPublicacion(){
    this.$request.get(
    '/Disponibilidad/',
    (response: any) => {
      console.log('pagos', response);
      this.publicaciones = response;
      this.publicaciones.forEach(eachObj => {
        console.log(eachObj.page_name);
        console.log(eachObj.published_at);
        eachObj.nombrePagina=eachObj.page_name;
        eachObj.publicado=eachObj.published_at;
      });
    },
    (response: any) => {
      console.log('error', response);
      if(response.statusText=="Unauthorized"){
        console.log("redireccion")
        this.$router.navigateByUrl('/sessions/signin');
      }
    });
  }

  confirm(content, value) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', centered: true })
    .result.then((result) => {
      this.confirmResut = `Closed with: ${result}`;
      this.eliminarPublicacion(value)
    }, (reason) => {
      this.confirmResut = `Dismissed with: ${reason}`;
    });
  }

  eliminarPublicacion(id){
    this.$request.delete(
    '/Catalogo/'+id+'/',
    (response: any) => {
      console.log('pagos', response);
      this.buscarPublicacion();
    },
    (response: any) => {
      console.log('error', response);
      if(response.statusText=="Unauthorized"){
        console.log("redireccion")
        this.$router.navigateByUrl('/sessions/signin');
      }
    });
  }

  titulo="Publicaciones";
  tipoProyecto="catalogo";
}