<div class="">
    <div class="breadcrumb">
      <h1>Usuarios</h1>
      <ul>
        <li>Usuarios</li>
      </ul>
    </div>
  
    <div class="separator-breadcrumb border-top"></div>

    <div class="card mb-4">
      <div class="card-body">
        <div class="row">
          <div class="col-lg-12 right text-right align-right">
            <a routerLink="/usuario/nuevo">
              <button class="btn btn-primary"><i class="i-Add"></i> Nuevo usuario</button>
            </a>
          </div>
          <div class="col-lg-12">
            <ngx-datatable
            style="box-shadow: none"
            class="material fullscreen"
            [columnMode]="'force'"
            [headerHeight]="50"
            [footerHeight]="50"
            [rowHeight]="60"
            [scrollbarV]="false"
            [limit]="10"
            [messages]="{emptyMessage:'No hay información capturada.'}"
            [rows]="usuarios">
              <!--ngx-datatable-column name="tipo_categoria" [width]="300">
                <ng-template ngx-datatable-header-template>
                  Tipo de categoria
                </ng-template>
              </ngx-datatable-column-->
              <ngx-datatable-column name="username">
                <ng-template ngx-datatable-header-template>
                  Usuario
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column name="nombre">
                <ng-template ngx-datatable-header-template>
                  Nombre
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column name="apellido">
                <ng-template ngx-datatable-header-template>
                  Apellido
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column name="email">
                <ng-template ngx-datatable-header-template>
                  Email
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column name="id">
                <ng-template ngx-datatable-header-template>
                  Acciones
                </ng-template>
                <ng-template ngx-datatable-cell-template let-value="value" let-row="row">
                  <a routerLink="/usuario/editar/{{value}}" style="margin-right: 20px;">
                    <i class="i-Pen-5"></i>
                  </a>
                  <a routerLink="/catalogo/clientes" (click)="confirm(modalConfirm, value)">
                    <i class="i-Close-Window"></i>
                  </a>
                </ng-template>
              </ngx-datatable-column>
            </ngx-datatable>
          </div>
        </div>      
      </div>
    </div>

</div>



<ng-template #modalConfirm let-modal>
    <div class="modal-header">
      <h4 class="modal-title" id="modal-title">Eliminar cliente</h4>
      <button type="button" class="close" aria-label="Close button" aria-describedby="modal-title" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <p><strong>¿Estas seguro de querer eliminar el cliente?</strong></p>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-outline-secondary btn-rounded" (click)="modal.dismiss('cancel')">Cancelar</button>
      <button type="button" ngbAutofocus class="btn btn-danger btn-rounded" (click)="modal.close('Ok')">Eliminar</button>
    </div>
</ng-template>