<div class="">
    <div class="breadcrumb">
      <h1>{{titulo}}</h1>
      <ul>
        <li>Catalogos</li>
        <li><a routerLink="/catalogo/sitios">Sitios</a></li>
        <li>{{titulo}}</li>
      </ul>
    </div>
  
    <div class="separator-breadcrumb border-top"></div>
<form method="post" class="form" (ngSubmit)="submit(pagosForm.valid)" #pagosForm="ngForm" novalidate>
    <div class="card mb-4">
      <div class="card-body">
        <div class="row">
          <div class="col-lg-4">
            <label>Sitio</label>
            <input type="text" class="form-control" id="nombre" placeholder="Sitio" [(ngModel)]="data.nombre" name="nombre" [className]="validation.nombre.invalid ? 'form-control error-class' : 'form-control'" (click)="limpiar('nombre')" (change)="limpiar('nombre')">
            <div class="errores" *ngIf="validation.nombre.invalid">
              <div *ngIf="validation.nombre.errors.required">
                Sitio es requerido
              </div>
              <div *ngIf="validation.nombre.errors.format">
                Sitio no tiene un formato valido
              </div>
            </div>
          </div>
          <div class="col-lg-4">
            <label>Pestaña de lectura</label>
            <input type="text" class="form-control" id="pestanaLectura" placeholder="Pestaña de lectura" [(ngModel)]="data.pestanaLectura" name="pestanaLectura">
          </div>
          <div class="col-lg-4">
            <label>Marca</label>
            <input type="text" class="form-control" id="marca" placeholder="Marca" [(ngModel)]="data.marca" name="marca">
          </div>
        </div>
        <div class="row">
          <div class="col-lg-6">
            <label>Front</label>
            <input type="text" class="form-control" id="front" placeholder="Front" [(ngModel)]="data.front" name="front">
          </div>
          <div class="col-lg-6">
            <label>API</label>
            <input type="text" class="form-control" id="api" placeholder="API" [(ngModel)]="data.api" name="api">
          </div>
        </div>
        <div class="row">
          <div class="col-lg-6">
            <label>Front Staging</label>
            <input type="text" class="form-control" id="frontStaging" placeholder="Front Staging" [(ngModel)]="data.frontStaging" name="frontStaging">
          </div>
          <div class="col-lg-6">
            <label>API Staging</label>
            <input type="text" class="form-control" id="apiStaging" placeholder="API Staging" [(ngModel)]="data.apiStaging" name="apiStaging">
          </div>
        </div>
        <div class="row">
          <div class="col-lg-4">
            <label>User</label>
            <input type="text" class="form-control" id="user" placeholder="User" [(ngModel)]="data.user" name="user">
          </div>
          <div class="col-lg-4">
            <label>Password</label>
            <input type="text" class="form-control" id="password" placeholder="Password" [(ngModel)]="data.password" name="password">
          </div>
          <div class="col-lg-4">
            <label>Token</label>
            <input type="text" class="form-control" id="token" placeholder="Token" [(ngModel)]="data.token" name="token">
          </div>
        </div>
        <div class="row">
          <div class="col-lg-4">
            <label>Booking (URL)</label>
            <input type="text" class="form-control" id="booking" placeholder="Booking (URL)" [(ngModel)]="data.booking" name="booking">
          </div>
          <div class="col-lg-4">
            <label>Price Travel (URL)</label>
            <input type="text" class="form-control" id="reservhotel" placeholder="Price Travel (URL)" [(ngModel)]="data.reservhotel" name="reservhotel">
          </div>
          <div class="col-lg-4">
            <label>Reserv Hotel (URL)</label>
            <input type="text" class="form-control" id="reservhotelMx" placeholder="Reserv Hotel (URL)" [(ngModel)]="data.reservhotelMx" name="reservhotelMx">
          </div>
        </div>
        <div class="row">
          <div class="col-lg-3">
            <label>Pestaña de QA</label>
            <input type="text" class="form-control" id="pestana" placeholder="Pestaña de QA" [(ngModel)]="data.pestana" name="pestana">
          </div>
          <div class="col-lg-3">
            <label>Imagen de hotel</label>
            <input type="text" class="form-control" id="hotelImg" placeholder="Imagen de hotel" [(ngModel)]="data.hotelImg" name="hotelImg">
          </div>
          <div class="col-lg-3">
            <label>Imagen transparente</label>
            <input type="text" class="form-control" id="imgTransparente" placeholder="Imagen transparente" [(ngModel)]="data.imgTransparente" name="imgTransparente">
          </div>
          <div class="col-lg-3">
            <label>ID de "transportation"</label>
            <input type="text" class="form-control" id="transporteID" placeholder="Imagen de transportation" [(ngModel)]="data.transporteID" name="transporteID">
          </div>
        </div>
        <div class="row">
          <div class="col-lg-2">
            <label>ID de "transport" (ES)</label>
            <input type="text" class="form-control" id="transporteEsID" placeholder="Pestaña de QA" [(ngModel)]="data.transporteEsID" name="transporteEsID">
          </div>
          <div class="col-lg-2">
            <label>Imagen de "transportation"</label>
            <input type="text" class="form-control" id="transporteImgID" placeholder="Imagen de hotel" [(ngModel)]="data.transporteImgID" name="transporteImgID">
          </div>
          <div class="col-lg-2">
            <label>Imagen de "transport" (ES)</label>
            <input type="text" class="form-control" id="transporteImgEsID" placeholder="Imagen transparente" [(ngModel)]="data.transporteImgEsID" name="transporteImgEsID">
          </div>
          <div class="col-lg-2">
            <label>ID de "Kids"</label>
            <input type="text" class="form-control" id="kidID" placeholder="Imagen de transportation" [(ngModel)]="data.kidID" name="kidID">
          </div>
          <div class="col-lg-2">
            <label>ID de "Kids" (ES)</label>
            <input type="text" class="form-control" id="kidEsID" placeholder="Imagen de transportation" [(ngModel)]="data.kidEsID" name="kidEsID">
          </div>
          <div class="col-lg-2">
            <label>Imagen de "Kids"</label>
            <input type="text" class="form-control" id="kidImgID" placeholder="Imagen de transportation" [(ngModel)]="data.kidImgID" name="kidImgID">
          </div>
        </div>
        <div class="row">
          <div class="col-lg-2">
            <label>Imagen de "Kids" (ES)</label>
            <input type="text" class="form-control" id="kidImgEsID" placeholder="Pestaña de QA" [(ngModel)]="data.kidImgEsID" name="kidImgEsID">
          </div>
          <div class="col-lg-2">
            <label>ID aniversario</label>
            <input type="text" class="form-control" id="aniversarioID" placeholder="Imagen de hotel" [(ngModel)]="data.aniversarioID" name="aniversarioID">
          </div>
          <div class="col-lg-2">
            <label>ID aniversario (ES)</label>
            <input type="text" class="form-control" id="aniversarioEsID" placeholder="Imagen transparente" [(ngModel)]="data.aniversarioEsID" name="aniversarioEsID">
          </div>
          <div class="col-lg-2">
            <label>ID aniversario imagen</label>
            <input type="text" class="form-control" id="aniversarioImgID" placeholder="Imagen de transportation" [(ngModel)]="data.aniversarioImgID" name="aniversarioImgID">
          </div>
          <div class="col-lg-2">
            <label>ID aniversario imagen (ES)</label>
            <input type="text" class="form-control" id="aniversarioImgEsID" placeholder="Imagen de transportation" [(ngModel)]="data.aniversarioImgEsID" name="aniversarioImgEsID">
          </div>
          <div class="col-lg-2">
            <label>ID Luna miel</label>
            <input type="text" class="form-control" id="lunaID" placeholder="Imagen de transportation" [(ngModel)]="data.lunaID" name="lunaID">
          </div>
        </div>
        <div class="row">
          <div class="col-lg-2">
            <label>ID Luna miel (ES)</label>
            <input type="text" class="form-control" id="lunaEsID" placeholder="Pestaña de QA" [(ngModel)]="data.lunaEsID" name="lunaEsID">
          </div>
          <div class="col-lg-2">
            <label>ID Luna miel imagen</label>
            <input type="text" class="form-control" id="lunaImgID" placeholder="Imagen de hotel" [(ngModel)]="data.lunaImgID" name="lunaImgID">
          </div>
          <div class="col-lg-2">
            <label>ID Luna miel imagen (ES)</label>
            <input type="text" class="form-control" id="lunaImgEsID" placeholder="Imagen transparente" [(ngModel)]="data.lunaImgEsID" name="lunaImgEsID">
          </div>
        </div>

        <div class="row">
          <div class="col-lg-6">
            <label>Texto transportation</label>
            <!--input type="text" class="form-control" id="lunaEsID" placeholder="Pestaña de QA" [(ngModel)]="data.lunaEsID" name="lunaEsID"-->
            <textarea class="form-control" id="transportacionEN" placeholder="Texto transportation" [(ngModel)]="data.transportacionEN" name="transportacionEN"></textarea>
          </div>
          <div class="col-lg-6">
            <label>Texto ninos adolescentes</label>
            <textarea class="form-control" id="ninosAdolescentesEN" placeholder="Texto ninos adolescentes" [(ngModel)]="data.ninosAdolescentesEN" name="ninosAdolescentesEN"></textarea>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-6">
            <label>Texto aniversario</label>
            <!--input type="text" class="form-control" id="lunaEsID" placeholder="Pestaña de QA" [(ngModel)]="data.lunaEsID" name="lunaEsID"-->
            <textarea class="form-control" id="aniversarioEN" placeholder="Texto aniversario" [(ngModel)]="data.aniversarioEN" name="aniversarioEN"></textarea>
          </div>
          <div class="col-lg-6">
            <label>Texto luna miel</label>
            <textarea class="form-control" id="lunaMielEN" placeholder="Texto luna miel" [(ngModel)]="data.lunaMielEN" name="lunaMielEN"></textarea>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-6">
            <label>Texto aniversario (ES)</label>
            <!--input type="text" class="form-control" id="lunaEsID" placeholder="Pestaña de QA" [(ngModel)]="data.lunaEsID" name="lunaEsID"-->
            <textarea class="form-control" id="aniversarioES" placeholder="Texto aniversario (ES)" [(ngModel)]="data.aniversarioES" name="aniversarioES"></textarea>
          </div>
          <div class="col-lg-6">
            <label>Texto luna miel (ES)</label>
            <textarea class="form-control" id="lunaMielES" placeholder="Texto luna miel (ES)" [(ngModel)]="data.lunaMielES" name="lunaMielES"></textarea>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-6">
            <label>Texto transportation (ES)</label>
            <!--input type="text" class="form-control" id="lunaEsID" placeholder="Pestaña de QA" [(ngModel)]="data.lunaEsID" name="lunaEsID"-->
            <textarea class="form-control" id="transportacionES" placeholder="Texto transportation (ES)" [(ngModel)]="data.transportacionES" name="transportacionES"></textarea>
          </div>
          <div class="col-lg-6">
            <label>Texto ninos adolescentes (ES)</label>
            <textarea class="form-control" id="ninosAdolescentesES" placeholder="Texto ninos adolescentes (ES)" [(ngModel)]="data.ninosAdolescentesES" name="ninosAdolescentesES"></textarea>
          </div>
        </div>

      </div>

      <div class="card-footer right text-right align-right">
        <a routerLink="/catalogo/sitios">
          <btn-loading type="button" btnClass="btn-primary btn-rounded btn-danger" style="margin-right: 20px;"><i class="i-Close-Window"></i> Cancelar</btn-loading>
        </a>
        <btn-loading type="submit" btnClass="btn-primary btn-rounded btn-success" [loading]="loading"><i class="i-Data-Save"></i> Guardar</btn-loading>

        <!--btn-loading class="btn btn-rounded btn-danger" [loading]="loading" style="margin-right: 10px;"><i class="i-Close-Window"></i> Cancelar</btn-loading>
        <btn-loading class="btn btn-rounded btn-success" [loading]="loading"><i class="i-Data-Save"></i> Guardar</btn-loading-->
      </div>
    </div>
</form>
</div>