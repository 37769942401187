import { Component, OnInit } from '@angular/core';
import { ProductService } from 'src/app/shared/services/product.service';
import { ResourceService } from '../../../resource.service';
import { Router, ActivatedRoute } from '@angular/router';

import { CustomValidators } from 'ngx-custom-validators';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DomSanitizer} from '@angular/platform-browser';

@Component({
  selector: 'app-usuario-nuevo',
  templateUrl: './usuario-nuevo.component.html',
  styleUrls: ['./usuario-nuevo.component.scss'],
  providers: [ResourceService]
})
export class UsuarioNuevoComponent implements OnInit {
  formBasic: FormGroup;
  products$;
  products=[];
  loading= false;
  loading1=false;

  data={
    first_name: "",
    last_name:"",
    username: null,
    email: "",
    direccion: "",
    tipo_interes:"",
    tipo:"",
    password:"",
    password2:"",
    groups: null
  }

  documento={

    documento: "",
    descripcion: "",
  }

  grupos=[];

  constructor(
    private productService: ProductService,
    private $request: ResourceService,
    private $router: Router,
    private fb: FormBuilder,
    private toastr: ToastrService
  ) { }

  ngOnInit() {
    this.buildFormBasic();
    console.log("Cotizacion");
    //this.products$ = this.productService.getProducts();
    this.products = [{
          id: 1,
          nombre: "Jorge",
          fecha_entrega: "1234567890",
          descripcion: "descripcion",
        }]
    this.buscarGrupo();
  }

  capturadocumento=true;
  guardarDoc(){}

  buildFormBasic() {
    this.formBasic = this.fb.group({
      experience: []
    });
  }

  buscarGrupo(){
    this.$request.get(
    '/Group/',
    (response: any) => {
      console.log('pagos', response);
      this.grupos = response;
    },
    (response: any) => {
      console.log('error', response);
      if(response.statusText=="Unauthorized"){
        console.log("redireccion")
        this.$router.navigateByUrl('/sessions/signin');
      }
    });
  }

  submit(valid) {
    this.loading = true;
    console.log("enviar");
    this.data.groups=[this.data.tipo]
    if(this.validForm()){
      console.log("valid form");
      this.$request.new(
      '/user/',
      this.data,
      (response: any) => {
        console.log('pagos', response);
        this.$router.navigateByUrl('/usuarios');
        this.toastr.success('Se guardo el cliente.', '¡Exito!', {progressBar: true});
      },
      (response: any) => {
        console.log('error', response);
        if(response.statusText=="Unauthorized"){
          console.log("redireccion")
          this.$router.navigateByUrl('/sessions/signin');
        }
      });
    }
    /*setTimeout(() => {
      this.loading = false;
      this.capturadocumento=false;
      //this.$router.navigateByUrl('/catalogo/clientes');
      this.toastr.success('Se guardo el cliente.', '¡Exito!', {progressBar: true});
    }, 3000);*/
  }

  validation={
    nombre: {invalid: false, errors:{required: false}},
    apellido: {invalid: false, errors:{required: false}},
    email: {invalid: false, errors:{format:false, required: false}},
    tipo: {invalid: false, errors:{required: false}}
  }

  validForm(){
    var valid=true;
    if(!this.data.email){
      valid=false;
      this.validation.email.invalid=true;
      this.validation.email.errors.required=true;
    }else{
      var expresion = /^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i;
      var hallado = this.data.email.match(expresion);
      if(!hallado){
        valid=false;
        this.validation.email.invalid=true;
        this.validation.email.errors.format=true;
      }
    }
    if(!this.data.first_name){
      valid=false;
      this.validation.nombre.invalid=true;
      this.validation.nombre.errors.required=true;
    }
    if(!this.data.last_name){
      valid=false;
      this.validation.apellido.invalid=true;
      this.validation.apellido.errors.required=true;
    }
    if(!this.data.tipo){
      valid=false;
      this.validation.tipo.invalid=true;
      this.validation.tipo.errors.required=true;
    }

    this.loading = false;

    return valid
  }
  validEmail(){
    console.log("validar");
    this.validation.email.invalid=false;
    this.validation.email.errors.format=false;
    if(!this.data.email){
      this.validation.email.invalid=true;
      this.validation.email.errors.required=true;
    }else{
      var expresion = /^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i;
      var hallado = this.data.email.match(expresion);
      if(!hallado){
        this.validation.email.invalid=true;
        this.validation.email.errors.format=true;
      }
    }
  }
  limpiar(id){
    if(id=="nombre"){
      this.validation.nombre.invalid=false;
      this.validation.nombre.errors.required=false;
    }
    if(id=="apellido"){
      this.validation.apellido.invalid=false;
      this.validation.apellido.errors.required=false;
    }
    if(id=="email"){
      this.validation.email.invalid=false;
      this.validation.email.errors.required=false;
      this.validation.email.errors.format=false;
    }
    if(id=="tipo"){
      this.validation.tipo.invalid=false;
      this.validation.tipo.errors.required=false;
    }
  }
}

@Component({
  selector: 'app-usuario-nuevo',
  templateUrl: './usuario-nuevo.component.html',
  styleUrls: ['./usuario-nuevo.component.scss'],
  providers: [ResourceService]
})
export class UsuarioEditarComponent implements OnInit {

  private sub: any;
  id: number;
  loading= false;
  loading1=false;
  data={
    first_name: "",
    last_name:"",
    username: null,
    email: "",
    direccion: "",
    tipo_interes:"",
    tipo:"",
    password:"",
    password2:"",
    groups: null
  }
  documentos=[];

  documento={
    cliente: 0,
    documento: "",
    descripcion: "",
  }

  capturadocumento=false;

  constructor(
    private productService: ProductService,
    private $request: ResourceService,
    private $router: Router,
    private fb: FormBuilder,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    private modalService: NgbModal,
    private sanitizer: DomSanitizer
  ) {
    this.sub = this.route.params.subscribe(params => {
        this.id = +params['id']; // (+) converts string 'id' to a number
      });
  }

  url="";
  grupos=[];

  ngOnInit() {
    console.log("Cotizacion");
    this.url="/catalogo/cliente/editar/"+this.id;
    this.buscarCliente()
    this.buscarGrupo();
  }

  buscarGrupo(){
    this.$request.get(
    '/Group/',
    (response: any) => {
      console.log('pagos', response);
      this.grupos = response;
    },
    (response: any) => {
      console.log('error', response);
      if(response.statusText=="Unauthorized"){
        console.log("redireccion")
        this.$router.navigateByUrl('/sessions/signin');
      }
    });
  }

  buscarCliente(){
    this.$request.get(
    '/user/'+this.id+'/',
    (response: any) => {
      console.log('pagos', response);
      this.data = response;
      this.data.password2 = response.password;
      this.data.tipo=this.data.groups[0];
      this.buscarDocumentos();
    },
    (response: any) => {
      console.log('error', response);
      if(response.statusText=="Unauthorized"){
        console.log("redireccion")
        this.$router.navigateByUrl('/sessions/signin');
      }
    });
  }

  buscarDocumentos(){
    this.$request.get(
    '/clientesDocument/?cliente='+this.id,
    (response: any) => {
      console.log('pagos', response);
      this.documentos = response;
      this.documentos.forEach(eachObj => {
        var n=eachObj.documento.split("/");
        eachObj.documento_name=n[6];
      });
    },
    (response: any) => {
      console.log('error', response);
      if(response.statusText=="Unauthorized"){
        console.log("redireccion")
        this.$router.navigateByUrl('/sessions/signin');
      }
    });
  }

  download(url, id){
    window.open(url);
  }

  descargar(url, id){
    window.location.href=url;
  }

  document1=null;

  view(content, id, url) {
    this.document1=this.sanitizer.bypassSecurityTrustResourceUrl(url);
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' })
    .result.then((result) => {
      console.log(result);
    }, (reason) => {
      console.log('Err!', reason);
    });
  }

  fileName;

  onChange($event, config) {
    const self = this;
    const fileList: FileList = $event.target.files;
    // this.data = {};
    if (fileList.length > 0) {
      const file: File = fileList[0];
      const myReader: FileReader = new FileReader();
      myReader.onloadend = (loadEvent: any) => {
        this.documento.documento = loadEvent.target['result'];
        //config.imagen1 = myReader.result;
        this.fileName = file.name;
        this.documento.descripcion = file.name;
        console.log(this.documento.documento);
        console.log(this.fileName);
      };
      myReader.readAsDataURL(file);
    }
  }

  guardarDoc(){
    this.documento.cliente=this.id;
    this.$request.new(
    '/clientesDocument/',
    this.documento,
    (response: any) => {
      console.log('pagos', response);
      //this.$router.navigateByUrl('/catalogo/clientes');
      this.toastr.success('Se guardo el documento.', '¡Exito!', {progressBar: true});
      this.buscarDocumentos();
    },
    (response: any) => {
      console.log('error', response);
      if(response.statusText=="Unauthorized"){
        console.log("redireccion")
        this.$router.navigateByUrl('/sessions/signin');
      }
    });
  }

  confirmResut;

  confirm(content, value) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', centered: true })
    .result.then((result) => {
      this.confirmResut = `Closed with: ${result}`;
      this.eliminarLista(value)
    }, (reason) => {
      this.confirmResut = `Dismissed with: ${reason}`;
    });
  }

  eliminarLista(id){
    this.$request.delete(
    '/clientesDocument/'+id+'/',
    (response: any) => {
      console.log('pagos', response);
      this.buscarDocumentos();
    },
    (response: any) => {
      console.log('error', response);
      if(response.statusText=="Unauthorized"){
        console.log("redireccion")
        this.$router.navigateByUrl('/sessions/signin');
      }
    });
  }

  submit(valid) {
    this.loading = true;
    console.log("enviar");
    this.data.groups=[this.data.tipo]
    if(this.validForm()){
      console.log("valid form");
      this.$request.update(
      '/user/'+this.id+'/',
      this.data,
      (response: any) => {
        console.log('pagos', response);
        this.$router.navigateByUrl('/usuarios');
        this.toastr.success('Se guardo el cliente.', '¡Exito!', {progressBar: true});
      },
      (response: any) => {
        console.log('error', response);
        if(response.statusText=="Unauthorized"){
          console.log("redireccion")
          this.$router.navigateByUrl('/sessions/signin');
        }
      });
    }
    /*setTimeout(() => {
      this.loading = false;
      this.capturadocumento=false;
      //this.$router.navigateByUrl('/catalogo/clientes');
      this.toastr.success('Se guardo el cliente.', '¡Exito!', {progressBar: true});
    }, 3000);*/
  }

  validation={
    nombre: {invalid: false, errors:{required: false}},
    apellido: {invalid: false, errors:{required: false}},
    email: {invalid: false, errors:{format:false, required: false}},
    tipo: {invalid: false, errors:{required: false}}
  }

  validForm(){
    var valid=true;
    if(!this.data.email){
      valid=false;
      this.validation.email.invalid=true;
      this.validation.email.errors.required=true;
    }else{
      var expresion = /^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i;
      var hallado = this.data.email.match(expresion);
      if(!hallado){
        valid=false;
        this.validation.email.invalid=true;
        this.validation.email.errors.format=true;
      }
    }
    if(!this.data.first_name){
      valid=false;
      this.validation.nombre.invalid=true;
      this.validation.nombre.errors.required=true;
    }
    if(!this.data.last_name){
      valid=false;
      this.validation.apellido.invalid=true;
      this.validation.apellido.errors.required=true;
    }
    if(!this.data.tipo){
      valid=false;
      this.validation.tipo.invalid=true;
      this.validation.tipo.errors.required=true;
    }

    this.loading = false;

    return valid
  }
  validEmail(){
    console.log("validar");
    this.validation.email.invalid=false;
    this.validation.email.errors.format=false;
    if(!this.data.email){
      this.validation.email.invalid=true;
      this.validation.email.errors.required=true;
    }else{
      var expresion = /^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i;
      var hallado = this.data.email.match(expresion);
      if(!hallado){
        this.validation.email.invalid=true;
        this.validation.email.errors.format=true;
      }
    }
  }
  limpiar(id){
    if(id=="nombre"){
      this.validation.nombre.invalid=false;
      this.validation.nombre.errors.required=false;
    }
    if(id=="apellido"){
      this.validation.apellido.invalid=false;
      this.validation.apellido.errors.required=false;
    }
    if(id=="email"){
      this.validation.email.invalid=false;
      this.validation.email.errors.required=false;
      this.validation.email.errors.format=false;
    }
    if(id=="tipo"){
      this.validation.tipo.invalid=false;
      this.validation.tipo.errors.required=false;
    }
  }
}
